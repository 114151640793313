import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { ToastrService } from 'ngx-toastr';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  count: any;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService, private toastr:ToastrService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._notificationsService.onApiDataChange.subscribe(res => {
    //   this.notifications = res;
    // });
    this.getAllNotifications();
  }

  getAllNotifications() {
    this._notificationsService.getAllNotifications().subscribe((res) => {
      this.notifications = res.data;
      this.count = res.count;
    },
      (error) => {
      });
  }

  read(id:any){
    this._notificationsService.readNotification(id).subscribe((res) => {
      this.getAllNotifications();
      this.toastr.success('Success', 'Notification read', {
        timeOut: 3000
      });
    },
      (error) => {
      });
  }
}
